import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		component: () => import("@/layouts/MainLayout.vue"),
		path: "/",
		redirect: "/es",

		children: [
			{
				path: "/",
				name: "index",
				redirect: "/pe",
				component: () => import("@/views/IndexView.vue"),
			},

			{
				path: "/pe",
				name: "pe",
				component: () => import("@/views/IndexView.vue"),
			},
			{
				path: "/es",
				name: "es",
				component: () => import("@/views/IndexView.vue"),
			},
			{
				path: "/:pathMatch(.*)*",
				name: "notFound",
				component: () => import("@/views/NotFoundView.vue"),
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_URL),
	routes,
});

export default router;
